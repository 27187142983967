<template>
  <div id='apply'>
    <div class="box_banner">
      <div class="box_banner_mid">
        <p class="banner_mid_title">{{$t('apply.bannerTitle')}}  </p>
        <p class="banner_mid_value">{{$t('apply.bannerValue')}}</p>
      </div>
    </div>
    <ul class="main_apply">
      <li class="apply_li" v-for="(item,index) in apply" :key="index">
        <div class="apply_box">
          <div class="apply_left">
            <div class="top_title">
              <div class="img">
                <p>0{{index+1}}</p>
              </div>
              <p class="name">{{item.name}}</p>
            </div>
            <p class="cen_text">{{item.text}}</p>
            <p class="bom_btn" v-show="item.url" @click="goUrl(item.url)">{{item.btnTtext}}</p>
            <el-tooltip effect="light" placement="top" v-show="item.code">
              <div slot="content"><img :src="item.code" alt=""></div>
              <p class="bom_btn">{{item.btnTtext}}</p>
            </el-tooltip>
          </div>
          <div class="apply_right">
            <img :src="item.phoneimg" alt="">
          </div>
        </div>

      </li>

    </ul>
    <!-- 生态伙伴 -->
    <div class="main_friend">
      <p class="apply_title">{{$t('apply.applyTitle')}}</p>
      <ul class="friend_ul">
        <img src="../assets/home_friend.png" alt="" class="friend_ul_img">
      </ul>
      <div class="friend_bom">
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  computed:{
    apply(){
      return [
        {
          name: this.$t('apply.applyHeader6'),
          text:this.$t('apply.applyValue6'),
          phoneimg: require("../assets/apply6.png"),
          btnTtext:this.$t('apply.applyGoto'),
          code: require("../assets/domain.png"),
          // url:'https://ylzh.pro/cloudwallet/index.html'
        },
        {
          name:this.$t('apply.applyHeader'),
          text:this.$t('apply.applyValue'),
          btnTtext:this.$t('apply.applyBtn'),
          phoneimg: require("../assets/apply_1.png"),
          // code: require("../assets/apply_two.png"),
          url:'https://ylzh.pro/cloudwallet/index.html'
        },
        {
          name: this.$t('apply.applyHeader2'),
          text:this.$t('apply.applyValue2'),
          phoneimg: require("../assets/apply_2.png"),
          btnTtext:this.$t('apply.applyBtn'),
          code: require("../assets/apply_two2.png"),
        },
        {
          name: this.$t('apply.applyHeader3'),
          text:this.$t('apply.applyValue3'),
          btnTtext:this.$t('apply.applyBtn'),
          phoneimg: require("../assets/apply_3.png"),
          code: require("../assets/apply_ylcz.jpg"),
        },
        {
          name: this.$t('apply.applyHeader4'),
          text:this.$t('apply.applyValue4'),
          btnTtext:this.$t('apply.applyBtn'),
          phoneimg: require("../assets/apply_4.png"),
          code: require("../assets/apply_two3.png"),
        },
        {
          name: this.$t('apply.applyHeader5'),
          text:this.$t('apply.applyValue5'),
          btnTtext:this.$t('apply.applyBtn'),
          phoneimg: require("../assets/apply_5.png"),
          url: "111",
        },
      ]
    }
  },
  mounted() {},
  methods: {
    goUrl(url) {
      console.log(url);
      
    window.open(url);
    },
  },
};
</script>-*+

<style lang='scss' scoped>
#apply {
  .main_banner {
    height: 437px;
    background-image: url("../assets/banner.png");
    background-size: 100% 100%;
  }
  .main_apply {
    min-width: 1200px;
    .apply_li {
      background-image: url("../assets/apply_back1.png");
      background-size: 100% 100%;
      height: 555px;
      display: flex;
      align-items: center;
      .apply_box {
        width: 1210px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        .apply_left {
          margin-right: 140px;
          height: 250px;
          width: 680px;
          .top_title {
            display: flex;
            align-items: center;
            .img {
              width: 98px;
              height: 98px;
              background-image: url("../assets/apply_icon1.png");
              background-size: 100% 100%;
              p {
                line-height: 98px;
                text-align: center;
                opacity: 0.5;
                font-size: 36px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #ffffff;
              }
            }
            .name {
              margin: 0 16px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #1d2023;
            }
          }
          .cen_text {
            // text-indent: 2em;
            width: 680px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3f454b;
            margin: 18px 0 36px;
          }
          .bom_btn {
            width: 84px;
            height: 33px;
            line-height: 33px;
            text-align: center;
            border-radius: 23px;
            border: 1px solid #3075ff;
            font-size: 14px;
            font-weight: 400;
            color: #3075ff;
          }
        }
        .apply_right {
          // width: 379px;
          height: 376px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    li:nth-child(even) {
      background-image: url("../assets/apply_back2.png");
      background-size: 100% 100%;
      height: 714px;
      .apply_box {
        flex-direction: row-reverse;
        .apply_left {
          margin-right: 0px;
          margin-left: 140px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        .top_title {
          flex-direction: row-reverse;
          .img {
            background-image: url("../assets/apply_icon2.png") !important;
          }
        }
      }
    }
  }
  .main_friend {
    height: 814px;
    background: #3075ff;
    .apply_title {
      padding: 68px 0 0px;
      font-size: 36px;
      font-weight: 600;
      color: #ffffff;
      width: 1200px;
      margin: 0 auto;
    }
    .friend_ul {
      width: 1260px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .friend_li {
        margin: 65px 30px 0;
        width: 175px;
        height: 175px;
        background: #ffffff;
        border-radius: 2px;
      }
    }
    .friend_bom {
      margin-top: 17px;
      height: 200px;
      background-image: url("../assets/about_bomback.png");
      background-size: 100% 100%;
    }
  }
}
.friend_ul_img {
  width: 1040px;
  height: 419px;
  display: block;
  margin: 48px auto -20px;
}
.box_banner {
  width: 100%;
  height: 437px;
  background-image: url("../assets/banner_img.png");
  background-size: 100% 437px;
}
.box_banner_mid {
  width: 1200px;
  margin: auto;
  color: #ffffff;
  font-size: 46px;
  font-weight: 600;
  position: relative;
  padding-top: 140px;
  box-sizing: border-box;
}
.banner_mid_title span {
  color: #78a0ff;
  font-size: 38px;
}
.banner_mid_value {
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  // text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.49);
}
</style>